import React from "react";
import { SvgIconProps } from "./interface";

export const ArrowLeft = (props: SvgIconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8125 9.99999C17.8125 10.2486 17.7137 10.4871 17.5379 10.6629C17.3621 10.8387 17.1236 10.9375 16.875 10.9375H5.39063L9.41563 14.9617C9.59175 15.1378 9.6907 15.3767 9.6907 15.6258C9.6907 15.8748 9.59175 16.1137 9.41563 16.2898C9.23951 16.466 9.00064 16.5649 8.75157 16.5649C8.5025 16.5649 8.26363 16.466 8.08751 16.2898L2.46251 10.6648C2.37511 10.5777 2.30576 10.4742 2.25844 10.3603C2.21112 10.2463 2.18677 10.1242 2.18677 10.0008C2.18677 9.87738 2.21112 9.75521 2.25844 9.64125C2.30576 9.5273 2.37511 9.42381 2.46251 9.33671L8.08751 3.71171C8.17471 3.6245 8.27824 3.55533 8.39218 3.50813C8.50612 3.46094 8.62824 3.43665 8.75157 3.43665C8.8749 3.43665 8.99702 3.46094 9.11096 3.50813C9.2249 3.55533 9.32843 3.6245 9.41563 3.71171C9.50284 3.79891 9.57201 3.90244 9.61921 4.01638C9.6664 4.13032 9.6907 4.25244 9.6907 4.37577C9.6907 4.4991 9.6664 4.62122 9.61921 4.73516C9.57201 4.8491 9.50284 4.95263 9.41563 5.03983L5.39063 9.06249H16.875C17.1236 9.06249 17.3621 9.16126 17.5379 9.33708C17.7137 9.51289 17.8125 9.75135 17.8125 9.99999Z"
        fill="black"
      />
    </svg>
  );
};
