import React from "react";
import { SvgIconProps } from "./interface";

export const SmileIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.4988 63.0821C49.6646 63.0821 63.5803 49.1664 63.5803 32.0006C63.5803 14.8347 49.6646 0.919067 32.4988 0.919067C15.3329 0.919067 1.41724 14.8347 1.41724 32.0006C1.41724 49.1664 15.3329 63.0821 32.4988 63.0821Z"
        fill="#FFD30D"
      />
      <path
        d="M32.5 64C14.8467 64 0.5 49.6533 0.5 32C0.5 14.3467 14.8467 0 32.5 0C50.1533 0 64.5 14.3467 64.5 32C64.5 49.6533 50.1533 64 32.5 64ZM32.5 1.83697C15.8754 1.83697 2.33697 15.3754 2.33697 32C2.33697 48.6246 15.8754 62.163 32.5 62.163C49.1246 62.163 62.663 48.6246 62.663 32C62.663 15.3754 49.1429 1.83697 32.5 1.83697Z"
        fill="#1F1F1F"
      />
      <path
        d="M22.5615 30.7875C24.3978 30.7875 25.8864 29.2989 25.8864 27.4626C25.8864 25.6263 24.3978 24.1377 22.5615 24.1377C20.7252 24.1377 19.2366 25.6263 19.2366 27.4626C19.2366 29.2989 20.7252 30.7875 22.5615 30.7875Z"
        fill="#1F1F1F"
      />
      <path
        d="M42.4372 30.7875C44.2735 30.7875 45.7621 29.2989 45.7621 27.4626C45.7621 25.6263 44.2735 24.1377 42.4372 24.1377C40.6009 24.1377 39.1123 25.6263 39.1123 27.4626C39.1123 29.2989 40.6009 30.7875 42.4372 30.7875Z"
        fill="#1F1F1F"
      />
      <path
        d="M32.4997 45.6309C27.8522 45.6309 23.5904 42.8938 21.6616 38.6688C21.4411 38.2095 21.6616 37.6584 22.1208 37.4564C22.5801 37.2359 23.1311 37.4564 23.3332 37.9156C24.9681 41.4977 28.5686 43.7939 32.4997 43.7939C36.4308 43.7939 40.0313 41.4793 41.6662 37.9156C41.8866 37.4564 42.4193 37.2543 42.8786 37.4564C43.3378 37.6768 43.5399 38.2095 43.3378 38.6688C41.409 42.8938 37.1472 45.6309 32.4997 45.6309Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
