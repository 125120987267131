import React from "react";
import { SvgIconProps } from "./interface";

export const FeatureIcon5 = (props: SvgIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="19.2" fill="#FFD30D" />
      <path
        d="M53.0936 47.625C53.0936 48.2466 52.8467 48.8427 52.4072 49.2823C51.9676 49.7218 51.3715 49.9688 50.7499 49.9688H13.2499C12.6283 49.9688 12.0321 49.7218 11.5926 49.2823C11.1531 48.8427 10.9061 48.2466 10.9061 47.625V16.375C10.9061 15.7534 11.1531 15.1573 11.5926 14.7177C12.0321 14.2782 12.6283 14.0313 13.2499 14.0312C13.8715 14.0312 14.4676 14.2782 14.9072 14.7177C15.3467 15.1573 15.5936 15.7534 15.5936 16.375V35.7109L24.0917 27.2109C24.3094 26.9924 24.5682 26.8191 24.853 26.7008C25.1379 26.5825 25.4434 26.5216 25.7518 26.5216C26.0603 26.5216 26.3657 26.5825 26.6506 26.7008C26.9355 26.8191 27.1942 26.9924 27.412 27.2109L31.9999 31.8047L40.3983 23.4062H38.2499C37.6283 23.4062 37.0321 23.1593 36.5926 22.7198C36.1531 22.2802 35.9061 21.6841 35.9061 21.0625C35.9061 20.4409 36.1531 19.8448 36.5926 19.4052C37.0321 18.9657 37.6283 18.7188 38.2499 18.7188H46.0624C46.684 18.7188 47.2801 18.9657 47.7197 19.4052C48.1592 19.8448 48.4061 20.4409 48.4061 21.0625V28.875C48.4061 29.4966 48.1592 30.0927 47.7197 30.5323C47.2801 30.9718 46.684 31.2188 46.0624 31.2188C45.4408 31.2188 44.8446 30.9718 44.4051 30.5323C43.9656 30.0927 43.7186 29.4966 43.7186 28.875V26.7266L33.6581 36.7891C33.4403 37.0076 33.1816 37.1809 32.8967 37.2992C32.6118 37.4175 32.3064 37.4784 31.9979 37.4784C31.6895 37.4784 31.384 37.4175 31.0991 37.2992C30.8142 37.1809 30.5555 37.0076 30.3378 36.7891L25.7499 32.1953L15.5936 42.3516V45.2812H50.7499C51.3715 45.2812 51.9676 45.5282 52.4072 45.9677C52.8467 46.4073 53.0936 47.0034 53.0936 47.625Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
