import React from "react";
import { SvgIconProps } from "./interface";

export const FeatureIcon8 = (props: SvgIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="19.2" fill="#FFD30D" />
      <path
        d="M54.4882 32.6914L48.2382 17.0664C48.0315 16.5509 47.6484 16.1256 47.1573 15.8662C46.6661 15.6069 46.0989 15.5304 45.5566 15.6504L34.3437 18.1328V14.8125C34.3437 14.1909 34.0967 13.5948 33.6572 13.1552C33.2177 12.7157 32.6215 12.4688 31.9999 12.4688C31.3783 12.4688 30.7822 12.7157 30.3426 13.1552C29.9031 13.5948 29.6562 14.1909 29.6562 14.8125V19.1816L17.4296 21.9004C17.0574 21.9829 16.7109 22.1551 16.4203 22.4018C16.1296 22.6486 15.9035 22.9625 15.7616 23.3164L9.51165 38.9258C9.39874 39.2077 9.34169 39.5088 9.34368 39.8125C9.34368 42.1816 10.5546 44.1934 12.7519 45.4766C14.337 46.3634 16.1211 46.8337 17.9374 46.8438C19.7537 46.8337 21.5378 46.3634 23.123 45.4766C25.3202 44.1934 26.5312 42.1816 26.5312 39.8125C26.5312 39.5141 26.4741 39.2184 26.3632 38.9414L21.1366 25.877L29.6562 23.9922V46.8438H27.3124C26.6908 46.8438 26.0947 47.0907 25.6551 47.5302C25.2156 47.9698 24.9687 48.5659 24.9687 49.1875C24.9687 49.8091 25.2156 50.4052 25.6551 50.8448C26.0947 51.2843 26.6908 51.5312 27.3124 51.5312H36.6874C37.309 51.5312 37.9052 51.2843 38.3447 50.8448C38.7842 50.4052 39.0312 49.8091 39.0312 49.1875C39.0312 48.5659 38.7842 47.9698 38.3447 47.5302C37.9052 47.0907 37.309 46.8438 36.6874 46.8438H34.3437V22.9434L42.2382 21.1855L37.6366 32.6895C37.5255 32.9671 37.4685 33.2634 37.4687 33.5625C37.4687 35.9316 38.6796 37.9434 40.8769 39.2266C42.4578 40.124 44.2445 40.5958 46.0624 40.5958C47.8803 40.5958 49.6671 40.124 51.248 39.2266C53.4452 37.9434 54.6562 35.9316 54.6562 33.5625C54.6562 33.2641 54.5991 32.9684 54.4882 32.6914ZM17.9374 42.1562C17.2128 42.1562 14.4218 41.791 14.0683 40.168L17.9374 30.498L21.8066 40.168C21.4531 41.791 18.662 42.1562 17.9374 42.1562ZM46.0624 35.9062C45.3378 35.9062 42.5468 35.541 42.1933 33.918L46.0624 24.248L49.9316 33.918C49.5781 35.541 46.787 35.9062 46.0624 35.9062Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
