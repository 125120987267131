import React from "react";
import { SvgIconProps } from "./interface";

export const FeatureIcon11 = (props: SvgIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="19.2" fill="#FFD30D" />
      <path
        d="M53.0936 47.625C53.0936 48.2466 52.8467 48.8427 52.4072 49.2823C51.9676 49.7218 51.3715 49.9688 50.7499 49.9688H13.2499C12.6283 49.9688 12.0321 49.7218 11.5926 49.2823C11.1531 48.8427 10.9061 48.2466 10.9061 47.625V16.375C10.9061 15.7534 11.1531 15.1573 11.5926 14.7177C12.0321 14.2782 12.6283 14.0313 13.2499 14.0312C13.8715 14.0312 14.4676 14.2782 14.9072 14.7177C15.3467 15.1573 15.5936 15.7534 15.5936 16.375V33.084L24.2069 25.5547C24.6113 25.2009 25.1249 24.9968 25.6618 24.9765C26.1987 24.9563 26.7262 25.1211 27.1561 25.4434L38.1366 33.6777L49.2069 23.9922C49.4337 23.7658 49.7045 23.5884 50.0026 23.4708C50.3006 23.3532 50.6196 23.298 50.9399 23.3086C51.2601 23.3191 51.5748 23.3952 51.8645 23.5322C52.1542 23.6692 52.4127 23.8641 52.6241 24.1049C52.8354 24.3457 52.9952 24.6273 53.0934 24.9323C53.1917 25.2373 53.2264 25.5592 53.1954 25.8781C53.1643 26.197 53.0682 26.5062 52.913 26.7865C52.7578 27.0668 52.5467 27.3123 52.2928 27.5078L39.7928 38.4453C39.3885 38.7991 38.8749 39.0032 38.338 39.0235C37.8011 39.0437 37.2735 38.8789 36.8436 38.5566L25.8632 30.3281L15.5936 39.3145V45.2812H50.7499C51.3715 45.2812 51.9676 45.5282 52.4072 45.9677C52.8467 46.4073 53.0936 47.0034 53.0936 47.625Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
