import React from "react";
import { SvgIconProps } from "./interface";

export const FeatureIcon1 = (props: SvgIconProps) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="19.2" fill="#FFD30D" />
      <path
        d="M25.3241 29.7813C25.6898 29.5785 25.9945 29.2815 26.2067 28.9213C26.419 28.561 26.531 28.1506 26.5311 27.7324V14.957C26.5312 14.5642 26.4325 14.1777 26.2441 13.833C26.0558 13.4883 25.7838 13.1965 25.4533 12.9843C25.1227 12.7721 24.7441 12.6465 24.3522 12.6188C23.9604 12.5912 23.5679 12.6625 23.2108 12.8262C19.5434 14.514 16.4358 17.2165 14.2553 20.6142C12.0748 24.0119 10.9127 27.9628 10.9061 32C10.9061 32.6582 10.9374 33.3203 10.9979 33.9727C11.0342 34.3603 11.1665 34.7328 11.3828 35.0565C11.5991 35.3801 11.8926 35.6448 12.2369 35.8267C12.5811 36.0085 12.9653 36.1017 13.3546 36.0978C13.7439 36.094 14.1261 35.9933 14.4667 35.8047L25.3241 29.7813ZM21.8436 19.1211V26.3477L15.7518 29.7266C16.3471 25.541 18.528 21.7442 21.8436 19.1211ZM50.3358 21.582C50.3143 21.5352 50.2889 21.4902 50.2635 21.4453C50.2382 21.4004 50.2128 21.3613 50.1854 21.3223C48.3239 18.1527 45.6667 15.5243 42.477 13.6973C39.2873 11.8704 35.6757 10.9083 31.9999 10.9063C31.3783 10.9063 30.7821 11.1532 30.3426 11.5927C29.9031 12.0323 29.6561 12.6284 29.6561 13.25V30.7363L14.6522 39.4746C14.3854 39.63 14.1519 39.8366 13.9652 40.0825C13.7785 40.3284 13.6422 40.6088 13.5642 40.9075C13.4861 41.2062 13.4679 41.5174 13.5105 41.8232C13.5531 42.129 13.6557 42.4234 13.8124 42.6895C16.1577 46.6817 19.7515 49.7914 24.0393 51.5388C28.3272 53.2862 33.0709 53.5742 37.5386 52.3584C42.0064 51.1426 45.95 48.4906 48.7611 44.8114C51.5722 41.1322 53.0947 36.6302 53.0936 32C53.0989 28.3462 52.1482 24.7546 50.3358 21.582ZM34.3436 15.7598C38.5116 16.3659 42.2874 18.5502 44.8905 21.8613L34.3436 28.0059V15.7598ZM31.9999 48.4063C29.5417 48.3996 27.1161 47.8438 24.9003 46.7793C22.6846 45.7149 20.7347 44.1687 19.1932 42.2539L33.1425 34.1289L33.2128 34.0899L47.2342 25.9199C48.2273 28.4087 48.5956 31.1029 48.307 33.7669C48.0185 36.4309 47.0817 38.9837 45.5788 41.2021C44.0758 43.4205 42.0523 45.2371 39.6853 46.4929C37.3182 47.7487 34.6795 48.4057 31.9999 48.4063Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
